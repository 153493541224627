<template>

	<ul class="uk-breadcrumb">
		<li><router-link to="/administracion">Inicio</router-link></li>
		<li><router-link to="/administracion/documentacion">Documentación</router-link></li>
		<li><router-link to="/administracion/documentacion/tipos/evento">Evento</router-link></li>
	</ul>

	<h2>Evento</h2>

	<p>Evento originado por un ente.</p>

	<representacion-json>
<pre class="json">{
	<span class="string">"ente"</span>: {
		<span class="string">"identificador"</span>: <span class="tipo">string</span>
	},
	<span class="string">"grupoDeEntes"</span>: {
		<span class="string">"identificador"</span>: <span class="tipo">string</span>
	},
	<span class="string">"variables"</span>: <span class="tipo"><router-link to="/administracion/documentacion/tipos/variables">Variables</router-link></span>
}</pre>
	</representacion-json>

	<definicion-campos>
		<definicion-campos-fila campo="ente.identificador">
			<p>Identificador único del ente en el grupo de entes.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="grupoDeEntes.identificador">
			<p>Identificador del grupo de entes al que pertenece el ente.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="variables">
			<template v-slot:tipo>
				<router-link to="/administracion/documentacion/tipos/variables">Variables</router-link>
			</template>
			<p>Variables a analizar.</p>
		</definicion-campos-fila>
	</definicion-campos>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import tablaDocumentacion from './tablaDocumentacion.vue'
import representacionJson from './representacionJson.vue'
import definicionCampos from './definicionCampos.vue'
import definicionCamposFila from './definicionCamposFila.vue'

export default defineComponent({
	components: {
		tablaDocumentacion,
		representacionJson,
		definicionCampos,
		definicionCamposFila
	},
})
</script>
