<template>

	<ul class="uk-breadcrumb">
		<li><router-link to="/administracion">Inicio</router-link></li>
		<li><router-link to="/administracion/documentacion">Documentación</router-link></li>
		<li><router-link to="/administracion/documentacion/recursos/entes">/grupos-de-entes/:identificador/entes/:identificador</router-link></li>
	</ul>

	<h2>/grupos-de-entes/<em>:identificador</em>/entes/<em>:identificador</em></h2>

	<p>Establece el número telefónico y la dirección de correo electrónico de un ente. Si el ente no existe, se creará.</p>

	<h4>Petición HTTP</h4>

	<div class="uk-tile uk-tile-muted uk-padding-small">
		<strong>POST</strong> {{ URL_BASE_API_PÚBLICA }}<strong>/grupos-de-entes/<em>:identificador-de-grupo</em>/entes/<em>:identificador-de-ente</em></strong>
	</div>

	<h4>Cuerpo de la petición</h4>

	<p>El cuerpo acepta un objeto JSON con el número telefónico y el correo electrónico.</p>

	<representacion-json>
<pre class="json">{
	<span class="string">"correoElectronico"</span>: <span class="tipo">string</span>,
	<span class="string">"numeroTelefonico"</span>: <span class="tipo">string</span>
}</pre>
	</representacion-json>

	<h4>Respuesta</h4>

	<tabla-documentacion titulo="Respuesta" :colspanTitulo="2">
		<tr>
			<td>
				<strong class="uk-text-success">Código HTTP 200</strong>
			</td>
			<td>
				<p>Procesado exitosamente.</p>
				<p>El cuerpo de la respuesta viene vacío.</p>
			</td>
		</tr>
		<tr>
			<td>
				<strong class="uk-text-danger">Código HTTP 400</strong>
			</td>
			<td>
				<p>Petición errada.</p>
			</td>
		</tr>
		<tr>
			<td>
				<strong class="uk-text-danger">Código HTTP 500</strong>
			</td>
			<td>
				<p>Error inesperado.</p>
			</td>
		</tr>
	</tabla-documentacion>


</template>

<script lang="ts">
import { defineComponent } from 'vue'
import tablaDocumentacion from './tablaDocumentacion.vue'
import representacionJson from './representacionJson.vue'
import definicionCampos from './definicionCampos.vue'
import definicionCamposFila from './definicionCamposFila.vue'
import { URL_BASE_API_PÚBLICA } from '@/global'

export default defineComponent({
	components: {
		tablaDocumentacion,
		representacionJson,
		definicionCampos,
		definicionCamposFila
	},
	setup() {
		return {
			URL_BASE_API_PÚBLICA
		}
	}
})
</script>
