<template>

	<ul class="uk-breadcrumb">
		<li><router-link to="/administracion">Inicio</router-link></li>
		<li><router-link to="/administracion/documentacion">Documentación</router-link></li>
		<li><router-link to="/administracion/documentacion/tipos/origen">Origen</router-link></li>
	</ul>

	<h2>Origen</h2>

	<p>Datos de origen del dispositivo desde donde se origina un evento.</p>

	<representacion-json>
<pre class="json">{
	<span class="string">"direccionIP"</span>: <span class="tipo">string</span>,
	<span class="string">"coordenadas"</span>: {
		<span class="string">"latitud"</span>: <span class="tipo">number</span>,
		<span class="string">"longitud"</span>: <span class="tipo">number</span>
	}
}</pre>
	</representacion-json>

	<definicion-campos>
		<definicion-campos-fila campo="direccionIP">
			<p>Dirección IP del dispositivo desde donde se origina el evento.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="coordenadas">
			<template v-slot:tipo>object</template>
			<p>Coordenadas de la ubicación física del dispositivo desde donde se origina el evento.</p>
		</definicion-campos-fila>
	</definicion-campos>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import tablaDocumentacion from './tablaDocumentacion.vue'
import representacionJson from './representacionJson.vue'
import definicionCampos from './definicionCampos.vue'
import definicionCamposFila from './definicionCamposFila.vue'

export default defineComponent({
	components: {
		tablaDocumentacion,
		representacionJson,
		definicionCampos,
		definicionCamposFila
	},
})
</script>
