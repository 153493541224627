<template>

	<ul class="uk-breadcrumb">
		<li><router-link to="/administracion">Inicio</router-link></li>
		<li><router-link to="/administracion/documentacion">Documentación</router-link></li>
		<li><router-link to="/administracion/documentacion/tipos/receptor-de-facturacion">ReceptorDeFacturacion</router-link></li>
	</ul>

	<h2>ReceptorDeFacturacion</h2>

	<p>Datos de la persona jurídica o natural a quien se factura.</p>

	<representacion-json>
<pre class="json">{
	<span class="string">"nombre"</span>: <span class="tipo">string</span>,
	<span class="string">"identificacion"</span>: <span class="tipo">string</span>,
	<span class="string">"numeroTelefonico"</span>: <span class="tipo">string</span>,
	<span class="string">"correoElectronico"</span>: <span class="tipo">string</span>,
	<span class="string">"direccionPostal"</span>: <span class="tipo"><router-link to="/administracion/documentacion/tipos/direccion-postal">DireccionPostal</router-link></span>
}</pre>
	</representacion-json>

	<definicion-campos>
		<definicion-campos-fila campo="nombre">
			<p>Nombre de la persona natural o jurídica a quien se factura la adquisición de un producto o servicio.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="identificacion">
			<p>Número de identificación de la persona a quien se factura la adquisición de un producto o servicio.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="numeroTelefonico">
			<p>Número telefónico de contacto de la persona a quien se factura la adquisición de un producto o servicio.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="correoElectronico">
			<p>Dirección de correo electrónico de contacto de la persona a quien se factura la adquisición de un producto o servicio.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="direccionPostal">
			<template v-slot:tipo>
				<router-link to="/administracion/documentacion/tipos/direccion-postal">DireccionPostal</router-link>
			</template>
			<p>Dirección postal a donde se remitirá la factura de la adquisición.</p>
		</definicion-campos-fila>
	</definicion-campos>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import tablaDocumentacion from './tablaDocumentacion.vue'
import representacionJson from './representacionJson.vue'
import definicionCampos from './definicionCampos.vue'
import definicionCamposFila from './definicionCamposFila.vue'

export default defineComponent({
	components: {
		tablaDocumentacion,
		representacionJson,
		definicionCampos,
		definicionCamposFila
	},
})
</script>
