<template>

	<div class="uk-overflow-auto uk-margin-bottom">
		<table class="uk-table uk-table-small uk-table-divider documentacion">
			<thead>
				<tr>
					<th :colspan="colspanTitulo">{{ titulo }}</th>
				</tr>
			</thead>
			<tbody>
				<slot></slot>
			</tbody>
		</table>
	</div>

</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	props: {
		colspanTitulo: {
			type: Number,
			default: 1
		},
		titulo: {
			type: String,
			required: true
		}
	},
})
</script>
