
import { defineComponent } from 'vue'
import tablaDocumentacion from './tablaDocumentacion.vue'
import representacionJson from './representacionJson.vue'
import { URL_BASE_API_PÚBLICA } from '@/global'

export default defineComponent({
	components: {
		tablaDocumentacion,
		representacionJson,
	},
	setup() {
		return {
			URL_BASE_API_PÚBLICA
		}
	}
})
