export interface IError {
	msj: string
}

export class error {

	/** Crea un error */
	static n(msj: string, err?: any): IError {
		console.error(err)
		return { msj }
	}

	/** Verifica si es un error */
	static e(e: any | IError): e is IError {
		return (e as IError).msj !== undefined
	}

	/** Si **err** es un error normalizado de tipo **IError**, lo retorna. En caso contrario, crea uno nuevo con los parámetros **msj**. */
	static asegurar(err: any, msj: string): IError {
		if (err && this.e(err)) {
			return err as IError
		}
	
		return this.n(msj, err)
	}

}