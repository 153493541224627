
import { defineComponent } from 'vue'

export default defineComponent({
	props: {
		campo: {
			type: String,
			required: true
		}
	},
})
