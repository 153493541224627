import { reactive } from 'vue'
import firebase from 'firebase'
import { App, Firestore } from './global'
import { error, IError } from './error'
import { texto, notificación } from './utilidades'
import { inicializarCuenta, restablecerCuenta } from './cuenta'

let User: firebase.User | null

interface IUsuario {
	identificador: string
	nombres: string
	apellidos: string
	correo: string
	cuentas: Array<string>
}

export let usuario: IUsuario = reactive({
	identificador: '',
	nombres: '',
	apellidos: '',
	correo: '',
	cuentas: []
})

interface IEstadoUsuario {
	inicializado: boolean
	autenticado: boolean
	autenticando: boolean
	cambiandoContraseña: boolean
}

export let estadoUsuario: IEstadoUsuario = reactive({
	inicializado: false,
	autenticado: false,
	autenticando: false,
	cambiandoContraseña: false
})

export const tokenUsuario = function(): Promise<string> {
	if (!User) {
		notificación.error('Sesión caducada')
		return Promise.reject()
	}

	return User.getIdToken()
}

export const inicializarUsuarioCuenta = function() {
	App.auth().onAuthStateChanged(
		onAuthStateChanged,
		() => {
			notificación.error('[7788] Ocurrió un error')
		}
	)
}

export async function iniciarSesión(correo: string, contraseña: string) {
	correo = correo.trim()
	contraseña = contraseña.trim()
	estadoUsuario.autenticando = true

	try {
		await App.auth().signInWithEmailAndPassword(correo, contraseña)
	} catch (e) {
		let err = '[2345] Ocurrió un error'
		if (e && typeof e === 'object') {
			let E = e as {code: string, message: string}
			if (E.code === 'auth/invalid-email') {
				err = 'Correo electrónico no válido'
			} else if (E.code === 'auth/wrong-password' || E.code === 'auth/user-not-found') {
				err = 'Correo o contraseña errados'
			}
		}

		estadoUsuario.autenticando = false
		console.error(e)
		notificación.error(err)
	}
}

export async function cambiarContraseña(contraseña1: string, contraseña2: string) {
	contraseña1 = contraseña1.trim()
	contraseña2 = contraseña2.trim()

	if (!contraseña1) {
		notificación.error('Falta la contraseña.')
		return Promise.reject()
	} else if (!contraseña2) {
		notificación.error('Debe confirmar la contraseña.')
		return Promise.reject()
	} else if (typeof contraseña1 !== 'string' || typeof contraseña2 !== 'string') {
		notificación.error('Contraseña errada.')
		return Promise.reject()
	}

	if (contraseña1 !== contraseña2) {
		notificación.error('Las contraseñas no coinciden.')
		return Promise.reject()
	} else if (contraseña1.length < 8) {
		notificación.error('La contraseña debe tener al menos ocho caracteres.')
		return Promise.reject()
	}

	estadoUsuario.cambiandoContraseña = true

	let U = App.auth().currentUser
	if (!U) {
		notificación.error('Su sesión expiró. Recargue la página e ingrese de nuevo.')
		return Promise.reject()
	}

	try {
		await U.updatePassword(contraseña1)
		estadoUsuario.cambiandoContraseña = false
	} catch (e) {
		estadoUsuario.cambiandoContraseña = false
		let msj = '[7538] Ocurrió un error.'
		if (e && typeof e === 'object') {
			let E = e as {code: string, message: string}
			if (E.code === 'auth/requires-recent-login') {
				msj = 'Para cambiar la contraseña se requiere que haya iniciado sesión recientemente. Cierre sesión y volver a inciar.'
			}
		}
		
		console.error(msj, e)
		notificación.error(msj)
		return Promise.reject()
	}

	notificación.correcto('Contraseña cambiada.')
	return Promise.resolve()
}

export function cerrarSesión() {
	App.auth().signOut()
}

async function onAuthStateChanged(u: firebase.User | null) {
	estadoUsuario.autenticado = false
	User = u

	if (!u) {
		restablecerUsuario()
		restablecerCuenta()
		return Promise.resolve()
	}

	estadoUsuario.autenticando = true
	let docUsuario: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>

	let rechazar = (e: IError) => {
		restablecerUsuario()
		notificación.error(e.msj)
		return Promise.reject()
	}

	try {
		docUsuario = await Firestore.collection('usuarios').doc(u.uid).get()
	} catch (e) {
		return rechazar(error.n('[7533] Ocurrió un error.', e))
	}

	let datosUsuario = docUsuario.data()
	if (!docUsuario.exists || !datosUsuario || !datosUsuario.cuentas || !datosUsuario.cuentas.length || !datosUsuario.cuentas[0]) {
		return rechazar(error.n('[7534] Ocurrió un error.'))
	}

	try {
		usuario.identificador = u.uid
		usuario.correo = u.email || ''
		usuario.nombres = texto.obligatorio(datosUsuario.nombres, '[5733] Datos de cuenta errados.')
		usuario.apellidos = texto.opcional(datosUsuario.apellidos, '[5734] Datos de cuenta errados.')
		usuario.cuentas = [datosUsuario.cuentas[0]]
	} catch (e) {
		return rechazar(error.asegurar(e, '[7537] Ocurrió un error.'))
	}

	if (usuario.cuentas.length == 0 || !usuario.cuentas[0]) {
		return rechazar(error.n('[7538] Ocurrió un error.'))
	}

	try {
		await inicializarCuenta(usuario.cuentas[0])
	} catch (e) {
		restablecerUsuario()
		return Promise.reject()
	}

	estadoUsuario.inicializado = true
	estadoUsuario.autenticado = true
	estadoUsuario.autenticando = false

	return Promise.resolve()
}

function restablecerUsuario() {
	if (User) {
		App.auth().signOut()
	}

	User = null
	usuario.identificador = ''
	usuario.nombres = ''
	usuario.apellidos = ''
	usuario.correo = ''
	usuario.cuentas = []
	estadoUsuario.inicializado = true
	estadoUsuario.autenticado = false
	estadoUsuario.autenticando = false
	estadoUsuario.cambiandoContraseña = false
}
