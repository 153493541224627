<template>

	<ul class="uk-breadcrumb">
		<li><router-link to="/administracion">Inicio</router-link></li>
		<li><router-link to="/administracion/documentacion">Documentación</router-link></li>
		<li><router-link to="/administracion/documentacion/tipos/variables">Variables</router-link></li>
	</ul>

	<h2>Variables</h2>

	<p>Variables a evaluar.</p>

	<representacion-json>
<pre class="json">{
	<span class="string">"origen"</span>: <span class="tipo"><router-link to="/administracion/documentacion/tipos/origen">Origen</router-link></span>,
	<span class="string">"dispositivo"</span>: <span class="tipo"><router-link to="/administracion/documentacion/tipos/dispositivo">Dispositivo</router-link></span>,
	<span class="string">"adquisicion"</span>: <span class="tipo"><router-link to="/administracion/documentacion/tipos/adquisicion">Adquisicion</router-link></span>,
	<span class="string">"otras"</span>: <span class="tipo">object</span>,
}</pre>
	</representacion-json>

	<definicion-campos>
		<definicion-campos-fila campo="origen">
			<template v-slot:tipo>
				<router-link to="/administracion/documentacion/tipos/origen">Origen</router-link>
			</template>
			<p>Datos de origen del evento.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="dispositivo">
			<template v-slot:tipo>
				<router-link to="/administracion/documentacion/tipos/dispositivo">Dispositivo</router-link>
			</template>
			<p>Datos del dispositivo desde el que se origina el evento.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="adquisicion">
			<template v-slot:tipo>
				<router-link to="/administracion/documentacion/tipos/adquisicion">Adquisicion</router-link>
			</template>
			<p>Datos de adquisición, cuando el evento se origina a partir de una compra en línea.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="otras">
			<template v-slot:tipo>object</template>
			<p>Variables personalizadas. Un objeto llave/valor con valores de tipos básicos (boolean, number, string, o null)</p>
		</definicion-campos-fila>
	</definicion-campos>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import tablaDocumentacion from './tablaDocumentacion.vue'
import representacionJson from './representacionJson.vue'
import definicionCampos from './definicionCampos.vue'
import definicionCamposFila from './definicionCamposFila.vue'

export default defineComponent({
	components: {
		tablaDocumentacion,
		representacionJson,
		definicionCampos,
		definicionCamposFila
	},
})
</script>
