<template>

	<ul class="uk-breadcrumb">
		<li><router-link to="/administracion">Inicio</router-link></li>
		<li><router-link to="/administracion/documentacion">Documentación</router-link></li>
		<li><router-link to="/administracion/documentacion/recursos/eventos">/eventos</router-link></li>
	</ul>

	<h2>POST /eventos</h2>

	<p>Crea un nuevo evento.</p>

	<h4>Petición HTTP</h4>

	<div class="uk-tile uk-tile-muted uk-padding-small">
		<strong>POST</strong> {{ URL_BASE_API_PÚBLICA }}<strong>/eventos</strong>
	</div>

	<h4>Cuerpo de la petición</h4>

	<p>El cuerpo acepta los datos del evento en formato JSON del objeto <router-link to="/administracion/documentacion/tipos/evento">Evento</router-link>.</p>

	<representacion-json>
<pre class="json">{
	<span class="string">"ente"</span>: {
		<span class="string">"identificador"</span>: <span class="tipo">string</span>
	},
	<span class="string">"grupoDeEntes"</span>: {
		<span class="string">"identificador"</span>: <span class="tipo">string</span>
	},
	<span class="string">"variables"</span>: <span class="tipo"><router-link to="/administracion/documentacion/tipos/variables">Variables</router-link></span>
}</pre>
	</representacion-json>

	<h4>Respuesta</h4>

	<tabla-documentacion titulo="Respuesta" :colspanTitulo="2">
		<tr>
			<td>
				<strong class="uk-text-success">Código HTTP 200</strong>
			</td>
			<td>
				<p>Procesado exitosamente.</p>
				<p>El cuerpo contiene la respuesta en formato JSON del objeto <router-link to="/administracion/documentacion/tipos/resultado-de-evento">ResultadoDeEvento</router-link>.</p>
			</td>
		</tr>
		<tr>
			<td>
				<strong class="uk-text-danger">Código HTTP 400</strong>
			</td>
			<td>
				<p>Petición errada.</p>
			</td>
		</tr>
		<tr>
			<td>
				<strong class="uk-text-danger">Código HTTP 500</strong>
			</td>
			<td>
				<p>Error inesperado.</p>
			</td>
		</tr>
	</tabla-documentacion>

	<hr> <!-- Termina POST, empieza GET -->

	<h2>GET /eventos/<em>:identificador</em></h2>

	<p>Crea un nuevo evento.</p>

	<h4>Petición HTTP</h4>

	<div class="uk-tile uk-tile-muted uk-padding-small">
		<strong>GET</strong> {{ URL_BASE_API_PÚBLICA }}<strong>/eventos/<em>:identificador</em></strong>
	</div>

	<h4>Cuerpo de la petición</h4>

	<p>El cuerpo irá vacío.</p>

	<h4>Respuesta</h4>

	<tabla-documentacion titulo="Respuesta" :colspanTitulo="2">
		<tr>
			<td>
				<strong class="uk-text-success">Código HTTP 200</strong>
			</td>
			<td>
				<p>Procesado exitosamente.</p>
				<p>El cuerpo contiene la respuesta en formato JSON del objeto <router-link to="/administracion/documentacion/tipos/resultado-de-evento">ResultadoDeEvento</router-link>.</p>
			</td>
		</tr>
		<tr>
			<td>
				<strong class="uk-text-danger">Código HTTP 400</strong>
			</td>
			<td>
				<p>Petición errada.</p>
			</td>
		</tr>
		<tr>
			<td>
				<strong class="uk-text-danger">Código HTTP 500</strong>
			</td>
			<td>
				<p>Error inesperado.</p>
			</td>
		</tr>
	</tabla-documentacion>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import tablaDocumentacion from './tablaDocumentacion.vue'
import representacionJson from './representacionJson.vue'
import definicionCampos from './definicionCampos.vue'
import definicionCamposFila from './definicionCamposFila.vue'
import { URL_BASE_API_PÚBLICA } from '@/global'

export default defineComponent({
	components: {
		tablaDocumentacion,
		representacionJson,
		definicionCampos,
		definicionCamposFila
	},
	setup() {
		return {
			URL_BASE_API_PÚBLICA
		}
	}
})
</script>
