<template>

	<ul class="uk-breadcrumb">
		<li><router-link to="/administracion">Inicio</router-link></li>
		<li><router-link to="/administracion/documentacion">Documentación</router-link></li>
		<li><router-link to="/administracion/documentacion/tipos/direccion-postal">DireccionPostal</router-link></li>
	</ul>

	<h2>DireccionPostal</h2>

	<p>Dirección postal.</p>

	<representacion-json>
<pre class="json">{
	<span class="string">"pais"</span>: <span class="tipo">string</span>,
	<span class="string">"subdivisionDePrimerNivel"</span>: <span class="tipo">string</span>,
	<span class="string">"subdivisionDeSegundoNivel"</span>: <span class="tipo">string</span>,
	<span class="string">"subdivisionDeTercerNivel"</span>: <span class="tipo">string</span>,
	<span class="string">"direccionFisica"</span>: <span class="tipo">string</span>
}</pre>
	</representacion-json>

	<definicion-campos>
		<definicion-campos-fila campo="pais">
			<p>Nombre o código ISO del país. Se prefiere el código ISO 3166-1 alfa-2 (código de dos letras).</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="subdivisionDePrimerNivel">
			<p>Entidad político-administrativa de primer nivel del país, como el estado, departamento, o provincia.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="subdivisionDeSegundoNivel">
			<p>Entidad político-administrativa de segundo nivel, como ciudad o municipio.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="subdivisionDeTercerNivel">
			<p>Entidad político-administrativa de tercer nivel.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="direccionFisica">
			<p>Dirección física del inmueble.</p>
		</definicion-campos-fila>
	</definicion-campos>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import tablaDocumentacion from './tablaDocumentacion.vue'
import representacionJson from './representacionJson.vue'
import definicionCampos from './definicionCampos.vue'
import definicionCamposFila from './definicionCamposFila.vue'

export default defineComponent({
	components: {
		tablaDocumentacion,
		representacionJson,
		definicionCampos,
		definicionCamposFila
	},
})
</script>
