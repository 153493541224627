
import { defineComponent } from 'vue'
import { inicializar } from './global'
import { inicializarUsuarioCuenta } from './usuario'

export default defineComponent({
  name: 'App',
	mounted() {
		inicializar()
		inicializarUsuarioCuenta()
	},
});
