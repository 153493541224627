<template>

	<ul class="uk-breadcrumb">
		<li><router-link to="/administracion">Inicio</router-link></li>
		<li><router-link to="/administracion/documentacion">Documentación</router-link></li>
		<li><router-link to="/administracion/documentacion/tipos/resultado-de-evento">ResultadoDeEvento</router-link></li>
	</ul>

	<h2>ResultadoDeEvento</h2>

	<p>Resultado de la petición HTTP de un evento.</p>

	<representacion-json>
<pre class="json">{
	<span class="string">"identificador"</span>: <span class="tipo">string</span>,
	<span class="string">"fiabilidad"</span>: <span class="tipo">number</span>,
	<span class="string">"verificacion"</span>: {
		<span class="string">"requerida"</span>: <span class="tipo">boolean</span>,
		<span class="string">"factoresDisponibles"</span>: <span class="tipo">string[]</span>
	}
}</pre>
	</representacion-json>

	<definicion-campos>
		<definicion-campos-fila campo="identificador">
			<p>Identificador único del evento.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="fiabilidad">
			<template v-slot:tipo>number</template>
			<p>Fiabilidad del evento. Es un número representando el porcentaje de fiabilidad en el rango <strong>(0, 1)</strong>.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="verificacion.requerida">
			<template v-slot:tipo>boolean</template>
			<p>Indica si se requiere confirmación por parte del ente mediante un código de verificación.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="verificacion.factoresDisponibles">
			<template v-slot:tipo>string[]</template>
			<p>Muestra los factores a los que se envió un código de verificación al ente. Los posibles valores son:</p>
			<p>
				<ul>
					<li>sms</li>
					<li>correoElectronico</li>
				</ul>
			</p>
			<p>Si está vacío y <code>verificacion.requerida</code> es <code>true</code>, significa que el ente no tiene configurado ningún método de verificación y por lo tanto no se le pudo enviar el código de verificación. En tal caso es responsabilidad de usted como integrador decidir qué acción tomar.</p>
		</definicion-campos-fila>
	</definicion-campos>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import tablaDocumentacion from './tablaDocumentacion.vue'
import representacionJson from './representacionJson.vue'
import definicionCampos from './definicionCampos.vue'
import definicionCamposFila from './definicionCamposFila.vue'

export default defineComponent({
	components: {
		tablaDocumentacion,
		representacionJson,
		definicionCampos,
		definicionCamposFila
	},
})
</script>
