<template>

	<tabla-documentacion titulo="Definición de campos" :colspanTitulo="2">
		<slot></slot>
	</tabla-documentacion>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import tablaDocumentacion from './tablaDocumentacion.vue'

export default defineComponent({
	components: {
		tablaDocumentacion
	},
})
</script>
