<template>

	<header class="uk-section-primary uk-preserve-color" style="height: 300px;">
		<div class="uk-margin-auto" style="max-width: 1200px; height: 100%; display: flex; flex-direction: column; padding: 0 10px 0 10px">
			<nav class="uk-navbar-container uk-navbar-transparent uk-light" uk-navbar>
				<div class="uk-navbar-right">
					<ul class="uk-navbar-nav">
						<li><router-link to="/administracion">Ingresar</router-link></li>
					</ul>
				</div>
			</nav>
			<div style="height: 100%; display: flex; flex-direction: column; justify-content: center; background-image: url(/img/fingerprint_unlock_gradient_stereo_phone.png); background-position: right; background-size: contain; background-repeat: no-repeat">
				<div style="color: white">
					<h1 style="color: white; font-size: 3em">
						<img src="/img/icons/verifico-blanco-100x100.png" width="80px" style="vertical-align: baseline;">Verifico
					</h1>
					<p class="uk-text-large">
						Prevención de suplantación de identidad.<br>
						Sistema antifraudes para comercios.
					</p>
				</div>
			</div>
		</div>
	</header>

	<main>

		<div style="padding: 60px 0">

			<h6 class="uk-text-center uk-text-muted uk-text-uppercase uk-margin-large-top uk-margin-small-bottom">Antifraudes - Identidad - Comportamiento</h6>

			<h1 class="uk-text-center uk-text-light uk-text-uppercase uk-margin-small" style="font-size: 2.2rem; letter-spacing: 1px;">Nuestras soluciones</h1>

			<div class="uk-margin-auto uk-margin-large-bottom" style="max-width: 800px">
				<p class="uk-text-center" style="color: #666">Nuestras soluciones de antifraudes, identidad, y comportamiento, se basan en el análisis de datos introducidos por el usuarios, metadatos del dispositivo desde donde se originan los eventos, y del comportamiento del usuario en un sitio web. Estos datos son contrastados con modelos aprendidos de eventos anteriores y calificados según su fiabilidad para tomas de decisiones.</p>
			</div>

			<div class="uk-child-width-expand@s uk-text-center uk-margin-auto" uk-grid style="max-width: 1200px">
				<div class="uk-padding-small">
					<span uk-icon="icon: cart; ratio: 1.5" style="color: blue; font-weight: 100"></span>
					<h4 class="uk-margin-small">Comercio en línea</h4>
					<p class="uk-text-small uk-margin-small" style="color: #666">La API de prevención de suplantación de identidad le permite detectar anomalías y prevenir casos de fraude en compras en línea en su comercio electrónico.</p>
				</div>
				<div class="uk-padding-small">
					<span uk-icon="icon: user; ratio: 1.5" style="color: blue; font-weight: 100"></span>
					<h4 class="uk-margin-small">Identidad de usuarios</h4>
					<p class="uk-text-small uk-margin-small" style="color: #666">Verifique la autenticidad de un usuario en el momento en que recibe una solicitud de registro o inicio de sesión en una aplicación conectada a internet.</p>
				</div>
				<div class="uk-padding-small">
					<span uk-icon="icon: bell; ratio: 1.5" style="color: blue; font-weight: 100"></span>
					<h4 class="uk-margin-small">Comportamiento de usuarios</h4>
					<p class="uk-text-small uk-margin-small" style="color: #666">Detecte el comportamiento de sus usuarios en su sitio web, obtenga un análisis de uso y tome decisiones para mejorar y potenciar su comunidad.</p>
				</div>
			</div>

		</div>

		<div style="background-color: #eee; padding: 60px 0">

			<h1 class="uk-text-center uk-text-light uk-text-uppercase" style="font-size: 2rem; letter-spacing: 1px;">API de prevención de<br>suplantación de identidad</h1>

			<div class="uk-margin-auto uk-margin-large-bottom" style="max-width: 700px">
				<p class="uk-text-center" style="color: #666">La API de prevención de suplantación de identidad puede detectar casos de suplantación de identidad y prevenir potenciales casos de fraudes para su comercio electrónico. La integración con factores de autenticación alternativos facilita el aseguramiento de la identidad de un usuario cuando se detecta un comportamiento anómalo.</p>
			</div>

			<div class="uk-margin-auto uk-margin-medium-bottom" style="max-width: 100px; border-bottom: 1px solid silver"></div>

			<div class="uk-text-center">
				<img src="/img/sitio-ejemplo-700x353.png">
			</div>

		</div>

		<div style="background-position: center; background-size: cover; background-image: url(https://images.unsplash.com/photo-1526289034009-0240ddb68ce3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80)">
			<div class="uk-text-center uk-text-large uk-padding-large uk-padding-remove-horizontal" style="background-color: rgba(0, 0, 0, 0.2); color: white;">
				<h1 class="uk-text-uppercase" style="color: white;">Comportamiento</h1>
				<h1 class="uk-text-uppercase" style="color: white;">Autenticidad</h1>
				<h1 class="uk-text-uppercase" style="color: white;">Confianza</h1>
				<h1 class="uk-text-uppercase" style="color: white;">Seguridad</h1>
			</div>
		</div>

		<div class="uk-padding-large uk-padding-remove-horizontal uk-text-center">

			<h6 class="uk-text-muted uk-text-uppercase uk-margin-small-bottom">Flexibilidad</h6>

			<h1 class="uk-text-light uk-margin-small-top" style="font-size: 2rem; letter-spacing: 1px;">Ajustado a sus necesidades</h1>

			<div class="uk-text-center uk-margin-auto" style="max-width: 700px">
				<p>Nuestra API permite ajustarse a las neesidades de su negocio y programar las reglas adecuadas acorde a las variables requeridas por su negocio. Además de las variables predeterminadas que ofrece nuestra API, puede hacer uso de variables personalizadas para una mayor flexibilidad y control.</p>
			</div>

		</div>

		<div class="uk-padding-large uk-padding-remove-horizontal uk-text-center" style="background-color: black; color: silver">
			<img src="/favicon.ico" style="height: 1.5em">
			<br>
			<strong>verifico</strong>
			<br>
			Antifraudes - Identidad - Comportamiento
		</div>

	</main>

</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Inicio',
});
</script>
