<template>
	<router-view></router-view>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { inicializar } from './global'
import { inicializarUsuarioCuenta } from './usuario'

export default defineComponent({
  name: 'App',
	mounted() {
		inicializar()
		inicializarUsuarioCuenta()
	},
});
</script>
