<template>

	<tr>
		<td>
			<strong>{{ campo }}</strong>
		</td>
		<td>
			<p>
				<strong>
					<slot name="tipo">string</slot>
				</strong>
			</p>
			<slot></slot>
		</td>
	</tr>

</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	props: {
		campo: {
			type: String,
			required: true
		}
	},
})
</script>
