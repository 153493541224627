import firebase from "firebase";
import { error } from './error'
import { notificación } from './utilidades'
import { tokenUsuario } from './usuario'

export let App: firebase.app.App
export let Firestore: firebase.firestore.Firestore

export const URL_BASE_API_PÚBLICA = 'https://api.verifico.co'
export const URL_BASE_API_PRIVADA = 'https://us-central1-nube-hexagonal.cloudfunctions.net/Ipa/v1'

let desconexiones = new Map()

export const inicializar = function() {
	App = firebase.initializeApp({
		apiKey: "AIzaSyBxCo8KxXCQfz8LYIvp3-dKrN6zRsY7JfU",
		authDomain: "nube-hexagonal.firebaseapp.com",
		projectId: "nube-hexagonal",
		storageBucket: "nube-hexagonal.appspot.com",
		messagingSenderId: "130036594424",
		appId: "1:130036594424:web:519adbc72b3ed082912d0d"
	})

	Firestore = App.firestore()
}

export function POST(recurso: string, datos: any) {
	return petición('POST', recurso, datos)
}

export function PUT(recurso: string, datos: any) {
	return petición('PUT', recurso, datos)
}

export function GET(recurso: string, datos: any) {
	return petición('GET', recurso, datos)
}

export function DELETE(recurso: string) {
	return petición('DELETE', recurso)
}

async function petición(metodo: string, recurso: string, datos?: any) {
	let eje = async () => {
		let token = await tokenUsuario()

		let res = await fetch(`${URL_BASE_API_PRIVADA}${recurso}`, {
			method: metodo,
			body: datos ? JSON.stringify(datos) : null,
			headers: {
				'Authorization': `Bearer ${token}`,
				'Content-Type': 'application/json',
			}
		})

		if (!res.ok) {
			let msj: string

			try {
				let rtJson = await res.json()
				msj = rtJson.error || ''
			} catch (e) {
				msj = atob(res.headers.get('X-Error') || '')
			}

			if (!msj) {
				msj = '[8888] Ocurrió un error.'
			}

			throw { msj }
		}

		return { identificador: res.headers.get('X-Identificador') || '' }
	}

	try {
		return await eje()
	} catch (err) {
		let E = error.asegurar(err, '[7777] Ocurrió un error inesperado.')
		notificación.error(E.msj)
		return Promise.reject({ notificado: true })
	}
}

export function desconexión(llave: string, f: (() => void)) {
	desconexiones.set(llave, f)
}

export function desconectar(llave: string) {
	let f = desconexiones.get(llave)
	if (!f) {
		return
	}

	f()
	desconexiones.delete(llave)
}

export function restablecerConexiones() {
	for (let [llave, f] of desconexiones) {
		f()
		desconexiones.delete(llave)
	}
}
