<template>

	<ul class="uk-breadcrumb">
		<li><router-link to="/administracion">Inicio</router-link></li>
		<li><router-link to="/administracion/documentacion">Documentación</router-link></li>
		<li><router-link to="/administracion/documentacion/tipos/dispositivo">Dispositivo</router-link></li>
	</ul>

	<h2>Dispositivo</h2>

	<p>Datos del dispositivo desde el que se origina un evento.</p>

	<representacion-json>
<pre class="json">{
	<span class="string">"identificador"</span>: <span class="tipo">string</span>,
	<span class="string">"plataforma"</span>: <span class="tipo">string</span>,
	<span class="string">"navegador"</span>: {
		<span class="string">"window"</span>: <span class="tipo">object</span>
	}
}</pre>
	</representacion-json>

	<definicion-campos>
		<definicion-campos-fila campo="identificador">
			<p>Identificador único del dispositivo desde donde se origina el evento.</p>
			<p>Ejemplo de posibles datos a usar:</p>
			<p>
				<ul>
					<li>Número de serie</li>
					<li>Dirección MAC</li>
					<li>IMEI</li>
				</ul>
			</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="plataforma">
			<p>Plataforma o sistema operativo del dispositivo desde donde se origina el evento.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="navegador.window">
			<p>Metadatos del objeto «window» del navegador web desde donde se origina el evento.</p>
			<p>Estos datos se obtienen automáticamente usando la biblioteca cliente del lado del navegador <a href="https://github.com/verifico/verifico-js/tree/main/packages/web-api" target="_blank">web-api</a>, o la biblioteca de utilidades <a href="https://github.com/verifico/verifico-js/blob/main/packages/web-util" target="_blank">web-util</a>.</p>
		</definicion-campos-fila>
	</definicion-campos>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import tablaDocumentacion from './tablaDocumentacion.vue'
import representacionJson from './representacionJson.vue'
import definicionCampos from './definicionCampos.vue'
import definicionCamposFila from './definicionCamposFila.vue'

export default defineComponent({
	components: {
		tablaDocumentacion,
		representacionJson,
		definicionCampos,
		definicionCamposFila
	},
})
</script>
