<template>

	<ul class="uk-breadcrumb">
		<li><router-link to="/administracion">Inicio</router-link></li>
		<li><router-link to="/administracion/documentacion">Documentación</router-link></li>
		<li><router-link to="/administracion/documentacion/recursos/eventos/verificar-codigo">/eventos/:identificador/verificar-codigo</router-link></li>
	</ul>

	<h2>/eventos/<em>:identificador</em>/verificar-codigo</h2>

	<p>Verifica un código requerido a un ente como segundo método de autenticación.</p>

	<h4>Petición HTTP</h4>

	<div class="uk-tile uk-tile-muted uk-padding-small">
		<strong>POST</strong> {{ URL_BASE_API_PÚBLICA }}<strong>/eventos/<em>:identificador-de-evento</em>/verificar-codigo</strong>
	</div>

	<h4>Cuerpo de la petición</h4>

	<p>El cuerpo acepta un objeto JSON que contiene el código ingresado por el ente.</p>

	<representacion-json>
<pre class="json">{
	<span class="string">"codigo"</span>: <span class="tipo">string</span>
}</pre>
	</representacion-json>

	<h4>Respuesta</h4>

	<tabla-documentacion titulo="Respuesta" :colspanTitulo="2">
		<tr>
			<td>
				<strong class="uk-text-success">Código HTTP 200</strong>
			</td>
			<td>
				<p>Procesado exitosamente.</p>
				<p>Retorna un objeto JSON que indica si el código fue verificado y es válido o no.</p>
<pre class="json">{
	<span class="string">"valido"</span>: <span class="tipo">boolean</span>
}</pre>
			</td>
		</tr>
		<tr>
			<td>
				<strong class="uk-text-danger">Código HTTP 400</strong>
			</td>
			<td>
				<p>Petición errada.</p>
			</td>
		</tr>
		<tr>
			<td>
				<strong class="uk-text-danger">Código HTTP 500</strong>
			</td>
			<td>
				<p>Error inesperado.</p>
			</td>
		</tr>
	</tabla-documentacion>


</template>

<script lang="ts">
import { defineComponent } from 'vue'
import tablaDocumentacion from './tablaDocumentacion.vue'
import representacionJson from './representacionJson.vue'
import { URL_BASE_API_PÚBLICA } from '@/global'

export default defineComponent({
	components: {
		tablaDocumentacion,
		representacionJson,
	},
	setup() {
		return {
			URL_BASE_API_PÚBLICA
		}
	}
})
</script>
