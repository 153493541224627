import { error, IError } from './error'
import firebase from 'firebase'


export const notificación = {
	msj: function(msj: string, stts: string) {
		let uikit = (window as any).UIkit
		if (uikit) {
			uikit.notification({message: msj, pos: 'bottom-center', timeout: 15000, status: stts})
		} else {
			console.error('UIKit no encontrado.')
			window.alert(msj)
		}
	},
	error: function(msj: string) {
		notificación.msj(msj, 'danger')
	},
	informacion: function(msj: string) {
		notificación.msj(msj, 'primary')
	},
	correcto: function(msj: string) {
		notificación.msj(msj, 'success')
	}
}

export const texto = {

	opcional: function(t: any, err: string): string {
		if (t === null || t === undefined) {
			return ''
		}

		if (typeof t === 'string') {
			return t.trim()
		}

		throw error.n(err)
	},

	obligatorio: function(t: any, err: string) {
		t = texto.opcional(t, err)
		if (!t) {
			throw error.n(err)
		}

		return t
	}

}

export const número = {

	opcional: function(d: any, err?: string): number | null {
		if (d === null || d === undefined || d === '') {
			return null
		}

		if (typeof d === 'string') {
			d = d.trim()
			if (!d) {
				return null
			}

			d = Number(d)
		}

		if (typeof d === 'number' && !isNaN(d) && isFinite(d)) {
			return d
		}

		if (err) {
			throw error.n(err)
		}

		return null
	},

	obligatorio: function(d: any, err: string): number {
		let n = número.opcional(d)
		if (n === null) {
			throw error.n(err)
		}

		return n
	}

}

export const fecha = {

	// si está en un formato desconocido, quedará en null si no se recibe error, o arrojará error si se recibe
	opcional: function(d: any, err?: string): Date | null {
		if (!d) {
			return null
		}

		if (d instanceof Date) {
			return d
		}

		if (d.seconds && typeof d.seconds === 'number') {
			return new Date(d.seconds * 1000)
		}

		if (err) {
			throw error.n(err)
		}

		return null
	},

	obligatorio: function(d: any, err: string): Date {
		let f = fecha.opcional(d)
		if (!f) {
			throw error.n(err)
		}

		return f
	}

}


export const arreglo = {
	comparar: function(arr1: any[], arr2: any[]) {
		if (arr1.length !== arr2.length) {
			return false
		}

		if (arr1.length === 0 && arr2.length === 0) {
			return true
		}

		for (let i in arr1) {
			if (typeof arr1[i] === 'object' && typeof arr1[i].length === 'number' && typeof arr2[i] === 'object' && typeof arr2[i].length === 'number') {
				if (!arreglo.comparar(arr1[i], arr2[i])) {
					return false
				}
			} else if (arr1[i] !== arr2[i]) {
				return false
			}
		}

		return true
	}
}


export async function obtenerListado(
	ref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> | firebase.firestore.Query<firebase.firestore.DocumentData>,
	constructor: (d: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) => any,
	estado: any
) {
	estado.listando = true

	let rechazar = (e: IError) => {
		estado.listando = false
		notificación.error(e.msj)
		return Promise.reject()
	}

	let qs: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>

	try {
		qs = await ref.get()
	} catch (e) {
		return rechazar(error.n('[7322] Ocurrió un error.', e))
	}

	estado.listado = []

	if (qs.empty) {
		estado.listando = false
		estado.listadoInicializado = true
		return Promise.resolve()
	}

	try {
		for (let d of qs.docs) {
			estado.listado.push(constructor(d))
		}
	} catch (e) {
		estado.listado = []
		return rechazar(error.asegurar(e, '[7323] Ocurrió un error.'))
	}

	estado.listando = false
	estado.listadoInicializado = true

	return Promise.resolve()
}
