<template>

	<ul class="uk-breadcrumb">
		<li><router-link to="/administracion">Inicio</router-link></li>
		<li><router-link to="/administracion/documentacion">Documentación</router-link></li>
		<li><router-link to="/administracion/documentacion/tipos/pago">Pago</router-link></li>
	</ul>

	<h2>Pago</h2>

	<p>Datos de un pago realizado.</p>

	<representacion-json>
<pre class="json">{
	<span class="string">"tarjeta"</span>: {
		<span class="string">"fechaDeExpiracion"</span>: <span class="tipo">string</span>,
		<span class="string">"ultimosCuatroDigitos"</span>: <span class="tipo">string</span>
	},
	<span class="string">"precio"</span>: {
		<span class="string">"moneda"</span>: <span class="tipo">string</span>,
		<span class="string">"monto"</span>: <span class="tipo">string</span>
	}
}</pre>
	</representacion-json>

	<definicion-campos>
		<definicion-campos-fila campo="tarjeta.fechaDeExpiracion">
			<p>Fecha de expiración de la tarjeta con la que se realiza un pago. Se desea el formato «MM/AA».</p>
			<p>Ejemplo:</p>
			<p>
				<ul>
					<li>12/26</li>
				</ul>
			</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="tarjeta.ultimosCuatroDigitos">
			<p>Últimos cuatro dígitos de la tarjeta con la que se realiza un pago.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="precio.moneda">
			<p>Moneda de pago de un producto o servicio adquirido. Se desea el código de tres letras de ISO 4217.</p>
			<p>Ejemplo:</p>
			<p>
				<ul>
					<li>COP</li>
				</ul>
			</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="precio.monto">
			<p>Monto pagado por un producto o servicio adquirido.</p>
			<p>Ejemplo:</p>
			<p>
				<ul>
					<li>74500.00</li>
				</ul>
			</p>
		</definicion-campos-fila>
	</definicion-campos>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import tablaDocumentacion from './tablaDocumentacion.vue'
import representacionJson from './representacionJson.vue'
import definicionCampos from './definicionCampos.vue'
import definicionCamposFila from './definicionCamposFila.vue'

export default defineComponent({
	components: {
		tablaDocumentacion,
		representacionJson,
		definicionCampos,
		definicionCamposFila
	},
})
</script>
