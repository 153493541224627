import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Inicio from '../views/Inicio.vue'
import IntroduccionDocumentacion from '../components/documentacion/introduccionDocumentacion.vue'
import TipoOrigen from '../components/documentacion/tipoOrigen.vue'
import TipoDireccionPostal from '../components/documentacion/tipoDireccionPostal.vue'
import TipoDestinatarioDeEnvio from '../components/documentacion/tipoDestinatarioDeEnvio.vue'
import TipoReceptorDeFacturacion from '../components/documentacion/tipoReceptorDeFacturacion.vue'
import TipoPago from '../components/documentacion/tipoPago.vue'
import TipoAdquisicion from '../components/documentacion/tipoAdquisicion.vue'
import TipoDispositivo from '../components/documentacion/tipoDispositivo.vue'
import TipoVariables from '../components/documentacion/tipoVariables.vue'
import TipoEvento from '../components/documentacion/tipoEvento.vue'
import TipoResultadoDeEvento from '../components/documentacion/tipoResultadoDeEvento.vue'
import RecursoEventos from '../components/documentacion/recursoEventos.vue'
import RecursoEventosVerificarCodigo from '../components/documentacion/recursoEventosVerificarCodigo.vue'
import RecursoEntes from '../components/documentacion/recursoEntes.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/administracion',
    name: 'Administracion',
    component: () => import('../views/Administracion/Administracion.vue'),
		children: [
			{
				path: '',
				name: 'Tablero',
				component: () => import('../views/Administracion/Tablero.vue')
			},
			{
				path: 'eventos',
				name: 'Eventos',
				component: () => import('../views/Administracion/Eventos.vue')
			},
			{
				path: 'entes',
				name: 'Entes',
				component: () => import('../views/Administracion/Entes.vue')
			},
			{
				path: 'grupos-de-entes',
				name: 'GruposDeEntes',
				component: () => import('../views/Administracion/GruposDeEntes.vue')
			},
			{
				path: 'reglas',
				name: 'Reglas',
				component: () => import('../views/Administracion/Reglas.vue')
			},
			{
				path: 'verificacion',
				name: 'Verificacion',
				component: () => import('../views/Administracion/VerificacionDeEntes.vue')
			},
			{
				path: 'documentacion',
				name: 'Documentacion',
				component: () => import('../views/Administracion/Documentacion.vue'),
				children: [
					{
						path: '',
						component: IntroduccionDocumentacion
					},
					{
						path: 'tipos/origen',
						component: TipoOrigen
					},
					{
						path: 'tipos/direccion-postal',
						component: TipoDireccionPostal
					},
					{
						path: 'tipos/destinatario-de-envio',
						component: TipoDestinatarioDeEnvio
					},
					{
						path: 'tipos/receptor-de-facturacion',
						component: TipoReceptorDeFacturacion
					},
					{
						path: 'tipos/pago',
						component: TipoPago
					},
					{
						path: 'tipos/adquisicion',
						component: TipoAdquisicion
					},
					{
						path: 'tipos/dispositivo',
						component: TipoDispositivo
					},
					{
						path: 'tipos/variables',
						component: TipoVariables
					},
					{
						path: 'tipos/evento',
						component: TipoEvento
					},
					{
						path: 'tipos/resultado-de-evento',
						component: TipoResultadoDeEvento
					},
					{
						path: 'recursos/eventos',
						component: RecursoEventos
					},
					{
						path: 'recursos/eventos/verificar-codigo',
						component: RecursoEventosVerificarCodigo
					},
					{
						path: 'recursos/entes',
						component: RecursoEntes
					}
				]
			},
			{
				path: 'integracion',
				name: 'Integracion',
				component: () => import('../views/Administracion/Integracion.vue')
			}
		]
	},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
	scrollBehavior(to, from, savedPosition) {
		return { top: 0 }
	}
})

export default router
