<template>

	<ul class="uk-breadcrumb">
		<li><router-link to="/administracion">Inicio</router-link></li>
		<li><router-link to="/administracion/documentacion">Documentación</router-link></li>
		<li><router-link to="/administracion/documentacion/tipos/adquisicion">Adquisicion</router-link></li>
	</ul>

	<h2>Adquisicion</h2>

	<p>Datos de una adquisición de producto o servicio.</p>

	<representacion-json>
<pre class="json">{
	<span class="string">"pago"</span>: <span class="tipo"><router-link to="/administracion/documentacion/tipos/pago">Pago</router-link></span>,
	<span class="string">"receptorDeFacturacion"</span>: <span class="tipo"><router-link to="/administracion/documentacion/tipos/receptor-de-facturacion">ReceptorDeFacturacion</router-link></span>,
	<span class="string">"destinatarioDeEnvio"</span>: <span class="tipo"><router-link to="/administracion/documentacion/tipos/destinatario-de-envio">DestinatarioDeEnvio</router-link></span>
}</pre>
	</representacion-json>

	<definicion-campos>
		<definicion-campos-fila campo="pago">
			<template v-slot:tipo>
				<router-link to="/administracion/documentacion/tipos/pago">Pago</router-link>
			</template>
			<p>Datos del pago realizado por una adquisición de un producto o servicio.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="receptorDeFacturacion">
			<template v-slot:tipo>
				<router-link to="/administracion/documentacion/tipos/receptor-de-facturacion">ReceptorDeFacturacion</router-link>
			</template>
			<p>Datos de la persona jurídica o natural a quien se factura una adquisicón de producto o servicio.</p>
		</definicion-campos-fila>
		<definicion-campos-fila campo="destinatarioDeEnvio">
			<template v-slot:tipo>
				<router-link to="/administracion/documentacion/tipos/destinatario-de-envio">DestinatarioDeEnvio</router-link>
			</template>
			<p>Datos de envío de un producto adquirido.</p>
		</definicion-campos-fila>
	</definicion-campos>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import tablaDocumentacion from './tablaDocumentacion.vue'
import representacionJson from './representacionJson.vue'
import definicionCampos from './definicionCampos.vue'
import definicionCamposFila from './definicionCamposFila.vue'

export default defineComponent({
	components: {
		tablaDocumentacion,
		representacionJson,
		definicionCampos,
		definicionCamposFila
	},
})
</script>
