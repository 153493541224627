
import { defineComponent } from 'vue'

export default defineComponent({
	props: {
		colspanTitulo: {
			type: Number,
			default: 1
		},
		titulo: {
			type: String,
			required: true
		}
	},
})
