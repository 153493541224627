import { reactive } from 'vue'
import firebase from 'firebase'
import { error, IError } from './error'
import { Firestore } from './global'
import { texto, notificación } from './utilidades'

export let RefCuenta: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>

interface ICuenta {
	identificador: string
	empresa: {
		nombreComercial: string
		nombreLegal: string
		país: string
	}
}

export let cuenta: ICuenta = reactive({
	identificador: '',
	empresa: {
		nombreComercial: '',
		nombreLegal: '',
		país: ''
	}
})

interface IEstadoCuenta {
	cargada: boolean
	cargando: boolean
}

let estadoCuenta: IEstadoCuenta = reactive({
	cargada: false,
	cargando: false
})

export async function inicializarCuenta(identificador: string): Promise<void> {
	estadoCuenta.cargando = true

	let docCuenta: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>

	let rechazar = (e: IError) => {
		restablecerCuenta()
		notificación.error(e.msj)
		return Promise.reject()
	}

	try {
		cuenta.identificador = texto.obligatorio(identificador, '[5732] Datos de cuenta errados.')
		RefCuenta = Firestore.collection('cuentas').doc(cuenta.identificador)
		docCuenta = await RefCuenta.get()
	} catch (e) {
		return rechazar(error.asegurar(e, '[7535] Ocurrió un error.'))
	}

	let datosCuenta = docCuenta.data()
	if (!datosCuenta) {
		return rechazar(error.n('[7536] Ocurrió un error.'))
	}

	try {
		cuenta.empresa.nombreComercial = texto.obligatorio(datosCuenta.empresa.nombreComercial, '[5735] Datos de cuenta errados.')
		cuenta.empresa.nombreLegal = texto.opcional(datosCuenta.empresa.nombreLegal, '[5736] Datos de cuenta errados.')
	} catch (e) {
		return rechazar(error.asegurar(e, '[7537] Ocurrió un error.'))
	}

	estadoCuenta.cargada = true
	estadoCuenta.cargando = false

	return Promise.resolve()
}


export function restablecerCuenta() {
	cuenta.identificador = ''
	cuenta.empresa.nombreComercial = ''
	cuenta.empresa.nombreLegal = ''
	cuenta.empresa.país = ''
	estadoCuenta.cargada = false
	estadoCuenta.cargando = false
}
