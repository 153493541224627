<template>

	<ul class="uk-breadcrumb">
		<li><router-link to="/administracion">Inicio</router-link></li>
		<li><router-link to="/administracion/documentacion">Documentación</router-link></li>
	</ul>

	<h1>API de prevención de suplantación de identidad</h1>

	<p>La API de prevención de suplantación de identidad le permite verificar la autenticidad de un ente en el momento en que éste origina un evento en un sistema conectado a internet.</p>

	<h3>Definiciones</h3>

	<p>Antes de continuar, definamos dos términos esenciales que deben entenderse con claridad:</p>

	<dl class="uk-description-list">
		<dt>Ente</dt>
		<dd>
			Un ente es un actor que interactúa en una aplicación conectada a internet.
			Algunos ejemplos de entes son:
			<ul>
				<li>Una persona <i>(Este es el tipo de ente más común)</i></li>
				<li>Una empresa</li>
				<li>Un bot</li>
			</ul>
		</dd>
		<dt>Evento</dt>
		<dd>
			Un evento es una acción originada por un ente en una aplicación conectada a internet.
			Algunos ejemplos de eventos son:
			<ul>
				<li>Un inicio de sesión en un sitio web o aplicación móvil</li>
				<li>Una compra en una tienda en línea</li>
			</ul>
		</dd>
	</dl>

	<h3>Cómo funciona</h3>

	<p>La verificación de autenticidad se lleva a cabo mediante el análisis de variables de eventos originados por un ente, a partir de los cuales se predice la probabilidad de que el evento haya sido originado por el ente que dice ser.</p>

	<p>Cada evento contiene datos, que llamaremos <i>Variables</i>, que contienen información relacionada con el origen del evento, el dispositivo físico desde el que se origina, y cualquier otro dato relevante para el evento, como por ejemplo datos de facturación cuando el evento se origina a partir de una compra en una tienda en línea.</p>

	<p>Para llevar a cabo la predicción de autenticidad, el sistema:</p>

	<ol>
		<li>Aprende de las variables comunes recibidas en cada evento y las etiqueta y pondera según su recurrencia y variabilidad de valores.</li>
		<li>Contrasta las variables de un evento entrante con las ponderadas de eventos comunes pasados.</li>
		<li>Genera una predicción estadística por cada variable y obtiene la media de todas ellas.</li>
	</ol>

	<p>Se ofrece además la posibilidad de integrar la API con un segundo factor de autenticación que permite que un ente verifique su autenticidad cuando la predicción del algoritmo determina que hay suficientes variables inusuales en el evento como para requerirlo. Este segundo factor de autenticación minimiza al máximo las integraciones que usted como cliente de la API debería implementar mediante un tercero para el adecuado manejo de eventos inusuales.</p>

	<h3>Casos de uso</h3>

	<p>Algunos ejemplos de casos de uso comunes son:</p>

	<ul>
		<li><strong>Prevención de suplantación de identidad y fraudes en compras en comercios electrónicos.</strong></li>
		<li><strong>Prevención de suplantación de identidad en autenticación de usuarios en aplicaciones web o móviles.</strong></li>
	</ul>

	<h3>API</h3>

	<h5>Opción 1</h5>
	<p>Puede usar una de nuestras bibliotecas cliente para una integración rápida:</p>
	<ul>
		<li>Biblioteca Go: <a href="https://github.com/verifico/verifico-go" target="_blank">https://github.com/verifico/verifico-go</a>.</li>
		<li>Biblioteca JavaScript para Node.js: <a href="https://github.com/verifico/verifico-js/tree/main/packages/node-api" target="_blank">https://github.com/verifico/verifico-js/tree/main/packages/node-api</a>.</li>
		<li>Biblioteca JavaScript para uso en el navegador: <a href="https://github.com/verifico/verifico-js/tree/main/packages/web-api" target="_blank">https://github.com/verifico/verifico-js/tree/main/packages/web-api</a>.</li>
	</ul>

	<h5>Opción 2</h5>
	<p>Integración mediante la API REST. Continúe leyendo la <router-link to="/administracion/documentacion/recursos/eventos">API REST JSON</router-link>. Si su proyecto es web, puede hacer uso de la <a href="https://github.com/verifico/verifico-js/tree/main/packages/web-util" target="_blank">biblioteca de utilidades web</a> para recolectar metadatos del navegador.</p>

</template>